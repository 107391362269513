<template>
    <div class="PodiumPlace" :data-number="number">
        <div class="background" />

        <Avatar class="avatar" :avatar="avatar" />

        <div class="number">
            {{ number }}
        </div>
        <div v-if="name" class="name">
            {{ name }}
        </div>
    </div>
</template>

<script>
import Avatar from '@/components/Avatar';
// import monster1 from './avatar-1_2x.png';
// import monster2 from './avatar-2_2x.png';
// import monster3 from './avatar-3_2x.png';

export default {
    components: { Avatar, },
    props: {
        avatar: {
            type: String
        },
        number: {
            type: Number,
            required: true
        },
        name: {
            type: String
        }
    },
    // created() {
    //     this.defaultAvatar = [monster1, monster2, monster3][this.number - 1];
    // }
};
</script>

<style scoped lang="scss">
$delta: 5em;
.PodiumPlace {
    $backgroundRelativeTop: 3em;
    position: relative;
    &[data-number='1'] {
        order: 2;
        padding-top: 0;
        .background {
            top: $delta * 0 + $backgroundRelativeTop;
        }
        .avatarDecoration {
            background-image: url('./podium-place-crown.svg');
        }
        // .avatar { background-image: url("./avatar-1_2x.png"); }
    }
    &[data-number='2'] {
        padding-top: $delta;
        order: 1;
        .background {
            top: $delta * 1 + $backgroundRelativeTop;
        }
        .avatarDecoration {
            background-image: url('./podium-place-stars.svg');
        }
        // .avatar { background-image: url("./avatar-2_2x.png"); }
    }
    &[data-number='3'] {
        padding-top: $delta * 2;
        order: 3;
        .background {
            top: $delta * 2 + $backgroundRelativeTop;
        }
        .avatarDecoration {
            background-image: url('./podium-place-stars.svg');
        }
        // .avatar { background-image: url("./avatar-3_2x.png"); }
    }
}
.background {
    $triangleHeight: 3em;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(-180deg, #0f2e63 20%, #13346c00 80%);
    clip-path: polygon(0% $triangleHeight, 50% 0, 100% $triangleHeight, 100% 100%, 0 100%);
}

.number {
    font-size: 300%;
    font-weight: bold;
    text-align: center;
    position: relative;
    margin-top: 0.1em;
}
.avatarDecoration {
    $size: 6em;
    position: relative;
    width: $size;
    height: $size;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.avatar {
    $size: 6em;
    position: relative;
    width: $size;
    height: $size;
    margin: auto;
    border: 0.3em solid $dashboard-itemBackground;
}
.name {
    position: relative;
    text-align: center;
    font-size: 120%;
    color: rgba(white, 0.8);

    animation: slide-up 1s;

    @keyframes slide-up {
        0% {
            transform: translateY(4em);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

@include media('<mobile') {
    .avatar {
        $size: 5em;
        width: $size;
        height: $size;
    }
    .number {
        font-size: 200%;
    }
    .name {
        font-size: 100%;
    }
}
</style>
