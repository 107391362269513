<template>
  <div class="Podium">
    <PodiumPlace
      v-for="(place, i) of places"
      :key="i"
      v-bind="place" />
  </div>
</template>

<script>
import PodiumPlace from './PodiumPlace';

export default {
    components: { PodiumPlace },
    props: {
        winners: {
            type: Array
        }
    },
    computed: {
        places() {
            const items = [{ number: 1 }, { number: 2 }, { number: 3 }];
            if (this.winners) {
                this.winners.forEach((winner, i) => {
                    if (i < 3) {
                        items[i].name = `${winner.firstName || ''} ${winner.lastName || ''}`;
                        items[i].avatar = winner.avatar;
                    }
                });
            }
            return items;
        }
    }
};
</script>

<style scoped lang="scss">
.Podium {
    display: flex;
}

.PodiumPlace {
    flex: 1;
    &[data-number='1'] {
        margin-left: 1em;
        margin-right: 1em;
    }
}
</style>
