<template>
  <div class="PodiumView">
    <div class="header">
      <div class="headerEdge headerEdge--left">
        <div class="exitButton --global-clickable" @click.stop="exit()">
          <svgicon class="exitIcon" icon="dashboard-icon" />
        </div>
      </div>

      <div class="spacer" />
    </div>

    <div class="body">
      <div class="mainSection">
        <Podium :winners="showResults ? playersInLeaderboard : null" />

        <div class="buttonSection">
          <template v-if="isOwner && !game.leaderboardReveal">
            <div class="adminButtons">
              <div :class="{ disabled: !enableStartGame }" class="adminButton --global-clickable" @click="startGame()">
                {{ $t('startGame') }}
              </div>
              <div :class="{ disabled: !enableStopGame }" class="adminButton --global-clickable" @click="endGame()">
                {{ $t('endGame') }}
              </div>
              <div
                :class="{ disabled: !enableLeaderboard }"
                class="adminButton --global-clickable"
                @click="revealResults()">
                {{ $t('revealResults') }}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="userButtons">
              <div v-if="!game.running && !game.startedAt" class="message">
                {{ $t('waitingForGameToStart') }}
              </div>
              <div v-else-if="game.running && me" class="message">
                {{ $t('waitingForGameToEnd') }}
              </div>
              <div v-else-if="!game.running && game.startedAt && !game.leaderboardReveal" class="message">
                {{ $t('waitingForResults') }}
              </div>
              <div
                v-else-if="game.running && !me"
                class="joinGameButton --global-clickable"
                :xclass="{ disabled: !game || !game.running }"
                @click="joinGame()">
                {{ $t('joinGame') }} <svgicon class="gameIcon" icon="game-icon-2" />
              </div>
            </div>
          </template>
        </div>

        <div class="footer" :class="{ hidden: !showProgressFooter }" @click="refreshPlayers()">
          <div class="progress" :style="{ width: `${progress * 100}%` }" />
          <div>{{ $tc('playersCompleted', completedPlayerCount) }}</div>
          <div>{{ $tc('playersJoined', totalPlayerCount) }}</div>
        </div>
      </div>
      <div v-if="showResults" class="players">
        <LeaderboardPlayer
          v-for="(player, i) of playersInLeaderboard"
          :key="player.userId"
          v-bind="player"
          :rank="i + 1" />
      </div>
    </div>

    <Confetti v-if="showResults" />
  </div>
</template>

<translations>
  playersJoined: '{count} Player Joined | {count} Players Joined'
  playersJoined_no: '{count} innlogget | {count} innlogget'
  playersCompleted: '{count} Player Completed | {count} Players Completed'
  playersCompleted_no: '{count} ferdig | {count} ferdige'

  startGame: 'Start'
  startGame_no: 'Start'
  endGame: 'Finish'
  endGame_no: 'Avslutt'
  revealResults: 'Results'
  revealResults_no: 'Resultater'
  joinGame: 'Join'
  joinGame_no: 'Bli med'
  waitingForGameToStart: 'Waiting for game to start ...'
  waitingForGameToStart_no: 'Venter på at spillet starter ...'
  waitingForGameToEnd: 'Waiting for game to end ...'
  waitingForGameToEnd_no: 'Venter på at alle er ferdige ...'
  waitingForResults: 'Waiting for results ...'
  waitingForResults_no: 'Venter på resultatet ...'
</translations>

<script>
import orderBy from 'lodash-es/orderBy';
import Podium from '../components/Podium';
import Confetti from '../components/Confetti';
import LeaderboardPlayer from '../components/LeaderboardPlayer';
import soundService from '@/services/sound-service';
import bgQuizMusic from '../data/audio/quizMusic.mp3';
import bgInitialMusic from '../data/audio/background-space-music.mp3'
import bgScoreMusic from '../data/audio/bgPodiumScore.mp3'

export default {
  components: { Podium, Confetti, LeaderboardPlayer },

  data() {
    return {
      game: {},
      players: [],
      leaderboard: [],
      refreshId: null,
      sounds: [bgInitialMusic, bgScoreMusic, bgQuizMusic],
      playScoreMusic: false,
    };
  },

  props: {
    gameId: {
      required: true,
    },
  },

  computed: {
    playersInLeaderboard() {
      const players = this.leaderboard
        .filter(player => player.time)
        .map((player, i) => {
          return Object.assign({}, player, {
            time: (player.time.hours || 0) * 60 * 60 + (player.time.minutes || 0) * 60 + (player.time.seconds || 0),
            rank: i + 1,
            isMe: this.userId === player.userId,
            total: this.numberOfGameQuestions,
          });
        });
      return orderBy(players, ['correct', 'time'], ['desc', 'asc']);
    },
    numberOfGameQuestions() {
      return this.game.options.numberOfGameQuestions || 0;
    },
    enableStartGame() {
      return !this.game.running && !this.game.startedAt && !this.game.leaderboardReveal;
    },
    enableStopGame() {
      return this.game.running;
    },
    enableLeaderboard() {
      return !this.game.running && this.game.startedAt && !this.game.leaderboardReveal;
    },
    me() {
      return this.players.find(p => p.userId === this.userId);
    },
    progress() {
      return this.totalPlayerCount ? this.completedPlayerCount / this.totalPlayerCount : 0;
    },
    isOwner() {
      return this.game && this.game.owner === this.userId;
    },
    userId() {
      return this.$store.getters["moduleAuth/profile"].id;
    },
    showProgressFooter() {
      return this.game.running;
    },
    totalPlayerCount() {
      return this.players.length;
    },
    completedPlayerCount() {
      return this.players.filter(p => p.completed).length;
    },
    showResults() {
      return this.game && this.game.leaderboardReveal;
    },
  },

  watch: {
    showResults(v){
      if(v){
        if(this.isOwner){
          soundService.stop(bgQuizMusic, 500);
          soundService.play(bgScoreMusic, { volume: 1.4, loop: true }, 'background');
        }
      } else {
        if(this.isOwner){
          soundService.play(bgQuizMusic, { volume: 0.4, loop: true }, 'background');
        }
      }

    }
  },

  async mounted() {
    soundService.preload(this.sounds);
    await this.refresh();
    // this.playInitialBgMusic();
  },

  beforeDestroy() {
    clearTimeout(this.refreshId);
    soundService.stop(bgInitialMusic, 500);
    soundService.stop(bgQuizMusic, 500);
    soundService.stop(bgScoreMusic, 500);
  },

  methods: {
    playInitialBgMusic(){
      if(!this.isOwner){
        this.game = this.$store.state.moduleMemoransier.games.find(g => g.id === this.gameId);

        if (!this.game.leaderboardReveal) {
          soundService.play(bgInitialMusic, { volume: 0.2, loop: true }, 'background');
        } else {
          soundService.play(bgScoreMusic, { volume: 0.2, loop: true }, 'background');
        }
      }
    },
    startGame() {
      if (this.enableStartGame) {
        this.$store.dispatch('moduleMemoransier/startGame', this.gameId);
      }
    },
    endGame() {
      if (this.enableStopGame) {
        this.$store.dispatch('moduleMemoransier/endGame', this.gameId);
      }
    },
    revealResults() {
      if (this.enableLeaderboard) {
        this.$store.dispatch('moduleMemoransier/revealLeaderboard', this.gameId);
       
      }
    },
    async refreshGame() {
      await this.$store.dispatch('moduleMemoransier/refresh');
    },
    async refreshPlayers() {
      this.players = await this.$store.dispatch('moduleMemoransier/getGamePlayers', this.gameId);
    },
    async refreshLeaderboard() {
      this.leaderboard = await this.$store.dispatch('moduleMemoransier/getLeaderboard', this.gameId);
    },
    joinGame() {
      this.$store.dispatch('moduleMemoransier/joinGame', this.gameId);
      const options = this.game.options;
      if (this.game.name === 'Quiz game') {
        this.$router.push({
          name: 'MemoransierQuiz',
          params: {
            gameId: this.gameId,
            courseId: options.courseId,
          },
        });
      } else {
        this.$router.push({
          name: 'MemoransierWarmup',
          params: { gameId: this.gameId || this.$route.params.gameId },
        });
      }
    },

    async refresh() {
      clearTimeout(this.refreshId);
      await this.refreshGame();
      this.game = this.$store.state.moduleMemoransier.games.find(g => g.id === this.gameId);

      if (this.game) {
        if (this.game.leaderboardReveal) {
          await this.refreshLeaderboard();
        }

        if (this.showProgressFooter) {
          this.refreshPlayers();
        }
      }

      this.refreshId = setTimeout(() => this.refresh(), 1000);
    },

    exit() {
      this.$router.push({
        path: '/home',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.PodiumView {
  background: linear-gradient(180deg, #0a1335 0%, #1e1456 93.87%);
  color: white;
  display: flex;
  flex-direction: column;
}
.body {
  flex: 1;
  overflow-y: auto;
}
.mainSection {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.buttonSection {
  height: 5em;
}
.modeButton {
  color: rgba(white, 0.3);
  margin-left: 0.5em;
  &.selected {
    color: rgba(white, 0.9);
  }
}
.toggleAdminMode {
  padding: 0.5em;
  .svg-icon {
    $size: 1.4em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.3);
  }
  &:not(.adminActive) {
    .svg-icon {
      visibility: hidden;
    }
  }
}
.joinGameButton {
  background-color: $standardButtonColor;
  color: white;
  padding: 1em 2em;
  border-radius: 4em;
  display: flex;
  align-items: center;
}
.gameIcon {
  $size: 1em;
  height: $size;
  fill: white;
  margin-left: 1em;
}
.message {
  padding: 1em 2em;
}
.congratulationLabel {
  color: rgba(white, 0.5);
  text-align: center;
}
.winner {
  text-align: center;
  font-size: 140%;
  font-weight: 600;
  margin-top: 0.3em;
}
.adminButtons {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.adminButton {
  background-color: $standardButtonColor;
  color: white;
  padding: 1em 1.5em;
  border-radius: 4em;
  cursor: pointer;
  &.disabled {
    cursor: default;
    opacity: 0.1;
  }
  &:not(:first-child) {
    margin-left: 1em;
  }
}
.userButtons {
  display: flex;
  justify-content: center;
}
.spacer {
  flex: 1;
}
.footer {
  height: $topBarHeight;
  position: relative;
  background: rgba(black, 0.1);
  color: rgba(white, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
  & > * {
    position: relative;
  }
  &.hidden {
    visibility: hidden;
  }
}
.progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0%;
  background-color: #1e1456;
}
.Confetti {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.Podium {
  flex-grow: 1;
  width: 60em;
  max-width: calc(100% - 2em);
  align-self: center;
  margin-top: 8em;
}
.header {
  height: $topBarHeight;
  display: flex;
  align-items: center;
  background: #0b1337;
  padding-right: 1em;
}
.headerEdge {
  width: 4em;
  align-self: stretch;
  display: flex;
}
.exitIcon {
  $size: 1.5em;
  width: $size;
  height: $size;
  fill: rgba(white, 0.6);
}
.exitButton {
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: 0 1em;
  padding-left: 2em;
}
.players {
  position: relative;
  top: -8em;
  width: 60em;
  max-width: calc(100% - 2em);
  align-self: center;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.LeaderboardPlayer.isMe:nth-child(n + 4) {
  order: -1; // NOTE: If me is on rank > 3, move to top
}
@include media('<mobile') {
  .Podium {
    margin-top: 4em;
  }
}
</style>
