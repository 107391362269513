<template>
  <canvas
    ref="canvas"
    class="Confetti" />
</template>

<script>
// from http://jsfiddle.net/Javalsu/vxP5q/743/
// alternative anim http://codepen.io/Gthibaud/pen/bNOZjd

function createConfettiEngine(canvas) {
    // globals
    let ctx;
    let W;
    let H;
    let mp = 150; // max particles
    let particles = [];
    let angle = 0;
    let confettiActive = true;
    let animationComplete = true;
    let reactivationTimerHandler;
    let animationHandler;

    // objects

    let particleColors = {
        colorOptions: [
            'DodgerBlue',
            'OliveDrab',
            'Gold',
            'pink',
            'SlateBlue',
            'lightblue',
            'Violet',
            'PaleGreen',
            'SteelBlue',
            'SandyBrown',
            'Chocolate',
            'Crimson'
        ],
        colorIndex: 0,
        colorIncrementer: 0,
        colorThreshold: 10,
        getColor: function () {
            if (this.colorIncrementer >= 10) {
                this.colorIncrementer = 0;
                this.colorIndex++;
                if (this.colorIndex >= this.colorOptions.length) {
                    this.colorIndex = 0;
                }
            }
            this.colorIncrementer++;
            return this.colorOptions[this.colorIndex];
        }
    };

    class ConfettiParticle {
        constructor(color) {
            this.x = Math.random() * W; // x-coordinate
            this.y = Math.random() * H - H; // y-coordinate
            this.r = randomFromTo(10, 30); // radius
            this.d = Math.random() * mp + 10; // density
            this.color = color;
            this.tilt = Math.floor(Math.random() * 10) - 10;
            this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
            this.tiltAngle = 0;
        }

        draw() {
            ctx.beginPath();
            ctx.lineWidth = this.r / 2;
            ctx.strokeStyle = this.color;
            ctx.moveTo(this.x + this.tilt + this.r / 4, this.y);
            ctx.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 4);
            return ctx.stroke();
        }

        stepParticle(particleIndex) {
            this.tiltAngle += this.tiltAngleIncremental;
            this.y += (Math.cos(angle + this.d) + 3 + this.r / 2) / 2;
            this.x += Math.sin(angle);
            this.tilt = Math.sin(this.tiltAngle - particleIndex / 3) * 15;
        }

        repositionParticle(xCoordinate, yCoordinate, tilt) {
            this.x = xCoordinate;
            this.y = yCoordinate;
            this.tilt = tilt;
        }

        checkForReposition(index) {
            if ((this.x > W + 20 || this.x < -20 || this.y > H) && confettiActive) {
                if (index % 5 > 0 || index % 2 === 0) {
                    // 66.67% of the flakes
                    this.repositionParticle(Math.random() * W, -10, Math.floor(Math.random() * 10) - 10);
                } else {
                    if (Math.sin(angle) > 0) {
                        // Enter from the left
                        this.repositionParticle(-5, Math.random() * H, Math.floor(Math.random() * 10) - 10);
                    } else {
                        // Enter from the right
                        this.repositionParticle(W + 5, Math.random() * H, Math.floor(Math.random() * 10) - 10);
                    }
                }
            }
        }
    }

    function resizeConfetti() {
        W = window.innerWidth;
        H = window.innerHeight;
        canvas.width = W;
        canvas.height = H;
    }

    function setGlobals() {
        ctx = canvas.getContext('2d');
        resizeConfetti();
    }

    function initializeConfetti() {
        particles = [];
        animationComplete = false;
        for (let i = 0; i < mp; i++) {
            let particleColor = particleColors.getColor();
            particles.push(new ConfettiParticle(particleColor));
        }
        startConfetti();
    }

    function draw() {
        ctx.clearRect(0, 0, W, H);
        let results = [];
        for (let i = 0; i < mp; i++) {
            results.push(particles[i].draw());
        }
        update();

        return results;
    }

    function randomFromTo(from, to) {
        return Math.floor(Math.random() * (to - from + 1) + from);
    }

    function update() {
        let remainingFlakes = 0;
        let particle;
        angle += 0.01;

        for (let i = 0; i < mp; i++) {
            particle = particles[i];
            if (animationComplete) return;

            if (!confettiActive && particle.y < -15) {
                particle.y = H + 100;
                continue;
            }

            particle.stepParticle(i);

            if (particle.y <= H) {
                remainingFlakes++;
            }
            particle.checkForReposition(i);
        }

        if (remainingFlakes === 0) {
            stopConfetti();
        }
    }

    function startConfetti() {
        W = window.innerWidth;
        H = window.innerHeight;
        canvas.width = W;
        canvas.height = H;
        (function animloop() {
            if (animationComplete) return null;
            animationHandler = window.requestAnimationFrame(animloop);
            return draw();
        })();
    }

    function clearTimers() {
        clearTimeout(reactivationTimerHandler);
        clearTimeout(animationHandler);
    }

    function deactivateConfetti() {
        confettiActive = false;
        clearTimers();
    }

    function stopConfetti() {
        animationComplete = true;
        if (ctx === undefined) return;
        ctx.clearRect(0, 0, W, H);
    }

    function restartConfetti() {
        clearTimers();
        stopConfetti();
        reactivationTimerHandler = setTimeout(function () {
            confettiActive = true;
            animationComplete = false;
            initializeConfetti();
        }, 100);
    }

    setGlobals();

    return {
        start: initializeConfetti,
        restart: restartConfetti,
        stop: deactivateConfetti,
        stopImmediately: stopConfetti,
        resize: resizeConfetti
    };
}

export default {
    mounted() {
        this.confetti = createConfettiEngine(this.$refs.canvas);

        this.startTimeoutId = setTimeout(this.confetti.start, 1000);
        this.endTimeoutId = setTimeout(this.confetti.stop, 5000);

        this._onWindowResize = () => this.confetti.resize();
        window.addEventListener('resize', this._onWindowResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this._onWindowResize);
        this.confetti.stopImmediately();
        clearTimeout(this.startTimeoutId);
        clearTimeout(this.endTimeoutId);
    }
};
</script>

<style scoped lang="scss">
.Confetti {
    display: block;
    pointer-events: none;
}
</style>
