<template>
  <div class="LeaderboardPlayer" :class="{ isMe }">
    <div class="rank">
      {{ rank }}
    </div>
    <Avatar
        class="avatar"
        :avatar="avatar"/>
    <div class="name"> {{ firstName }} {{ lastName }} </div>
    <div class="count"> {{ correct }} {{ $t('correct') }}</div>
    <div class="time"> {{ time }} {{ $t('seconds') }} </div>
  </div>
</template>

<translations>
  correct: correct
  correct_no: riktige
  seconds: seconds
  seconds_no: sekunder
</translations>

<script>
import Avatar from '@/components/Avatar';

export default {
  components: { Avatar, },
  props: {
    avatar: {
      type: String,
    },
    firstName: {
      type: String,
    },
    lastName: {
      type: String,
    },
    rank: {
      type: Number,
    },
    correct: {
      type: Number,
    },
    score: {
      type: Number,
    },
    total: {
      type: Number,
    },
    time: {
      type: Number,
    },
    isMe: {
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
.LeaderboardPlayer {
  padding: 0.5em 1em;
  // border: 1px solid red;
  display: flex;
  align-items: center;

  &.isMe {
    background-color: rgba(black, 0.1);
  }
}
.rank {
  width: 3em;
}
.avatar {
  $size: 4em;
  width: $size;
  height: $size;
  margin-right: 1em;
  border: 0.3em solid $dashboard-itemBackground;
}
.name {
  flex: 1;
  font-weight: 600;
  font-size: 120%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.count {
  padding-right: 1em;
}
.time {
  text-align: right;
  margin-left: auto;
}
</style>
